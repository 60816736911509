@import "src/styles/base";

$size: 120px;

.circular-icon {
  height: $size;
  width: $size;
  border-radius: calc(#{$size} / 2);
  line-height: $size;

  i,
  span {
    font-size: calc(#{$size} / 2);
  }

  &.primary {
    background-color: $primary;

    i,
    span {
      color: $secondary;
    }
  }

  &.secondary {
    background-color: $secondary;

    i,
    span {
      color: $primary;
    }
  }
}
