@import "src/styles/base";

.info-card {
  background-color: $background;
  padding: $padding-large;
  box-shadow: $box-shadow;

  section {
    &:not(:last-of-type) {
      margin-bottom: $padding-large;
    }
  }
}
