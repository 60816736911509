#opening-hours-container {
  color: white;

  #opening-hours-timetable {
    max-width: 200px;
  }
}

@media screen and (max-width: 991px) {
  .banner {
    &.large {
      background-position-y: 0 !important;
    }
  }
}
