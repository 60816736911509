#mainImage {
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }
}

.testimonials-container {
  max-height: 660px;
  overflow-y: auto;
  overflow-x: hidden;
}
