$teal: rgb(139, 210, 213);
$softGrey: #3c3c3c;
$lightGrey: rgb(240, 240, 240);
$danger: #bf5329;

// Themes
$primary: $teal;
$secondary: white;
$background: $lightGrey;
$fontColour: $softGrey;

$primaryDarker: darken($primary, 20%);

$padding-scale: 2;
$padding: 16px;
$padding-large: $padding * $padding-scale;
$padding-extra-large: $padding-large * $padding-scale;
$padding-small: $padding / $padding-scale;
$padding-extra-small: $padding-small / $padding-scale;

$box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

$headerFont: "modulusFont";
