@import "src/styles/base";

.nav-link {
  padding: $padding-extra-small $padding-small;
  border-bottom: 2px solid transparent;
  color: inherit;

  &--active {
    color: $primary !important;
  }

  &:hover {
    color: inherit;
    border-bottom-color: $primary;
  }

  &--no-effects {
    border-bottom-width: 0;
    padding: 0;
  }
}
