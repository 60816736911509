@import "variables";

.underlined {
  display: inline-block;
  padding: 1px 4px 2px 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: $primary;

  &.underlined-heavy {
    padding: 2px 8px 4px 2px;
    border-bottom-width: 4px;
  }
}

.error {
  color: $danger;
}
