@import "src/styles/base";

$testimonialCaptionWidth: 20px;
$testimonialCaptionOffset: 80%;

.testimonial {
  position: relative;
  margin-bottom: calc(80px + #{$testimonialCaptionWidth});

  .testimonial-content,
  .testimonial-by {
    margin: 0;
  }

  .testimonial-content {
    position: relative;
    padding: $padding-large;
    border-radius: 5px;
    background-color: white;

    &:after {
      position: absolute;
      content: "";
      top: 100%;
      width: 0;
      height: 0;
      border-left: $testimonialCaptionWidth solid transparent;
      border-right: $testimonialCaptionWidth solid transparent;
      border-top: $testimonialCaptionWidth solid white;
    }
  }

  .testimonial-by {
    position: absolute;
    top: calc(100% + #{$testimonialCaptionWidth * 1.5});
    color: $primary;
    font-weight: bold;
  }

  &.left {
    .testimonial-content {
      &:after {
        right: $testimonialCaptionOffset;
      }
    }

    .testimonial-by {
      right: $testimonialCaptionOffset;
    }
  }

  &.right {
    .testimonial-content {
      &:after {
        left: $testimonialCaptionOffset;
      }
    }

    .testimonial-by {
      left: $testimonialCaptionOffset;
    }
  }
}
