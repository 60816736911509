@import "src/styles/base";

.banner {
  position: relative;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $background;

  h1 {
    margin: 0;
    padding: 180px 0;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px #ccc;
  }

  &.medium {
    &.fixed {
      height: 300px;
    }
  }

  &.large {
    height: auto;

    h1 {
      font-size: 72px;
    }
  }
}
