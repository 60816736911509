@import "src/styles/variables";

.levitate-navbar {
  background-color: white !important;
  box-shadow: $box-shadow;

  &__branding-group {
    display: flex;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-right: $padding-small;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__link {
    padding: $padding-small 0;
  }

  .navbar-brand {
    font-family: $headerFont;
  }

  .navbar-collapse {
    justify-content: space-between;
  }

  .rhs {
    display: none;
  }

  @media screen and (min-width: 997px) {
    .lhs {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__link {
      padding: $padding-small;
    }

    .rhs {
      display: block;
    }
  }
}

