@import "src/styles/base";

.footer {
  position: relative;
  z-index: 99;
  color: white !important;
  background-color: $primary;
  padding-top: $padding;

  * {
    color: white;
  }

  a {
    &:hover {
      text-decoration: none;
      color: darken(white, 10%);
    }
  }

  ul {
    list-style-type: none;

    &:nth-child(3) {
      text-align: right;
    }

    li {
      .glyphicon {
        margin-right: 10px;
      }
    }
  }

  p {
    color: white;
  }

  img {
    max-width: 50%;
  }

  @media screen and (max-width: 767px) {
    text-align: center;

    ul {
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
}
