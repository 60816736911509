@import "src/styles/base";

.levitate-map {
  width: 100%;
  height: 500px;
  background-color: grey;

  .directions-icon {
    &.directions-icon-arrive {
      background-image: '/images/branding/map-icon.jpg';
      background-size: 26px;
      background-color: #8bd2d5;
    }
  }

  @media screen and (max-width: 767px) {
    height: 200px;
  }
}

$mapMarkerSize: 40px;
.marker {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-bottom: $padding-extra-small;
    width: $mapMarkerSize;
    height: $mapMarkerSize;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
  }

  &__text {
    font-size: 16px;
    font-weight: bold;
    font-family: $headerFont;
  }
}
