@import "src/styles/base";

.descriptive-image {
  display: flex;
  flex-direction: column;
  background-color: $background;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: $box-shadow;
  padding-bottom: $padding;

  .banner {
    padding: $padding-large;
  }

  > div {
    padding: 10px 20px;
  }

  h2 {
    margin-bottom: 0;
  }

  p {
    flex: 1;
    padding: 10px 20px;
    margin-bottom: 0;
  }
}
