@import "src/styles/variables";

.faq-question {
  display: inline-block;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: grey;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    border-bottom-color: $primary;
  }
}
