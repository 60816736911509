@import "src/styles/base";

.consultation-step {
  margin-top: $padding-large;
  margin-bottom: $padding-large;
  max-width: 28%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .consultation-step {
    max-width: none;
  }
}
