@import "src/styles/base";

.icon-card {
  padding: $padding-large;

  &.primary {
    background-color: $primary;
    color: white;
  }

  &.secondary {
    background-color: $secondary;
  }

  .circular-icon,
  h4 {
    margin-bottom: $padding;
  }
}
