@import "variables";

.no-padding {
  padding: 0 !important;
}

.padding-container {
  padding: $padding !important;

  &-large {
    padding: $padding-large;
  }

  &-extra-large {
    padding: $padding-extra-large;
  }
}

.padding-top {
  padding-top: $padding !important;

  &-large {
    padding-top: $padding-large !important;
  }

  &-extra-large {
    padding-top: $padding-extra-large;
  }
}

.padding-right {
  padding-right: $padding !important;

  &-large {
    padding-right: $padding-large !important;
  }

  &-extra-large {
    padding-right: $padding-extra-large;
  }
}

.padding-bottom {
  padding-bottom: $padding !important;

  &-large {
    padding-bottom: $padding-large !important;
  }

  &-extra-large {
    padding-bottom: $padding-extra-large;
  }
}

.padding-left {
  padding-left: $padding !important;

  &-large {
    padding-left: $padding-large !important;
  }

  &-extra-large {
    padding-left: $padding-extra-large;
  }
}

.no-margin {
  margin: 0 !important;
}

.margin-container {
  margin: $padding !important;

  &-large {
    margin: $padding-large;
  }

  &-extra-large {
    margin: $padding-extra-large;
  }
}

.margin-top {
  margin-top: $padding !important;

  &-large {
    margin-top: $padding-large !important;
  }

  &-extra-large {
    margin-top: $padding-extra-large;
  }
}

.margin-right {
  margin-right: $padding !important;

  &-large {
    margin-right: $padding-large !important;
  }

  &-extra-large {
    margin-right: $padding-extra-large;
  }
}

.margin-bottom {
  margin-bottom: $padding !important;

  &-large {
    margin-bottom: $padding-large !important;
  }

  &-extra-large {
    margin-bottom: $padding-extra-large;
  }
}

.margin-left {
  margin-left: $padding !important;

  &-large {
    margin-left: $padding-large !important;
  }

  &-extra-large {
    margin-left: $padding-extra-large;
  }
}

.required-field {
  color: grey;

  &:after {
    content: "*Required";
    font-size: 14px;
    font-style: italic;
  }
}
